import { defineMessages } from 'react-intl';

const messages = defineMessages({
  seoTitle: 'Page Not Found - LodgeLink',
  heading: 'Oops! 404 - PAGE NOT FOUND',
  description: 'The page you are looking for might have moved or is temporarily unavailable.',
  buttonLabel: 'Go To Dashboard'
});

export default messages;
