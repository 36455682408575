import React, { Component } from 'react';
import { flowRight as compose } from 'lodash';

import * as Sentry from '@sentry/nextjs';
import ErrorPage from '../components/ErrorPage';
import ErrorPage404 from '../components/ErrorPage404';

import withLocaleProvider from '../hocs/withLocaleProvider';

class ErrorPages extends Component {
  static async getInitialProps(contextData) {
    await Sentry.captureUnderscoreErrorException(contextData);

    let statusCode = '';

    if (contextData.res) {
      const { statusCode: resStatusCode } = contextData.res;
      statusCode = resStatusCode;
    } else if (contextData.err) {
      const { statusCode: errStatusCode } = contextData.err;
      statusCode = errStatusCode;
    }

    return { statusCode };
  }

  render() {
    const { statusCode, statusMessage } = this.props;
    return statusCode === 404 ? <ErrorPage404 /> : <ErrorPage statusCode={statusCode} statusMessage={statusMessage} />;
  }
}

export default compose(withLocaleProvider)(ErrorPages);
