import PropTypes from 'prop-types';
import React, { Component } from 'react';

import withActiveLocale, { withActiveLocalePropType } from '../../hocs/withActiveLocale';

import { Link } from '../../routes';

/**
 * LocaleLink extends the functionality provided by `next-routes` to better support
 * how we are handling localizations.
 *
 * It will generate links to routes with the locale param automatically pre-populated
 * with the current locale.
 *
 * If I am viewing the site in the `abc` locale and set the route param on this component
 * to `Home` it would generate a link pointing to `/abc/homepage`.
 *
 * See:
 * https://github.com/fridays/next-routes#link-example
 */
class LocaleLink extends Component {
  static propTypes = {
    /**
     * The currently active locale.
     */
    activeLocale: withActiveLocalePropType.isRequired,
    /**
     * Route params.
     */
    params: PropTypes.shape({}),
    /**
     * Route name or URL.
     */
    route: PropTypes.string.isRequired
  };

  static defaultProps = {
    activeLocale: { locale: { id: 'en' } },
    params: {},
    route: ''
  };

  /**
   * Automatically appends the active locale to the params object
   * that has been passed in.
   */
  getParams() {
    const { activeLocale, params } = this.props;

    return {
      ...params,
      locale: activeLocale.id ?? 'en'
    };
  }

  /**
   * Strips off the props that don't need to be passed through to
   * the next-routes Link component.
   */
  getProps() {
    const { activeLocale, params, ...propsPassThrough } = this.props;
    return propsPassThrough;
  }

  render() {
    return <Link params={this.getParams()} {...this.getProps()} />;
  }
}

export default withActiveLocale(LocaleLink);
