import React, { Component } from 'react';
import { flowRight as compose } from 'lodash';
import { intlShape, injectIntl } from 'react-intl';
import PageBackgroundWrapper from '../BuildingBlocks/PageBackgroundWrapper/PageBackgroundWrapper';
import Button from '../BuildingBlocks/Button';
import Grid from '../Utility/Grid';
import VerticalSpacing from '../Utility/VerticalSpacing';
import Text from '../BuildingBlocks/Text';

import withAnalytics, { withAnalyticsPropType } from '../../hocs/withAnalytics';
import withRouting from '../../hocs/withRouting';

import PageSEO from '../PageSEO';
import LocaleLink from '../LocaleLink';

import messages from './messages';

class ErrorPage404 extends Component {
  static propTypes = {
    analytics: withAnalyticsPropType.isRequired,
    intl: intlShape.isRequired
  };

  componentDidMount() {
    const { analytics } = this.props;
    analytics.trackRoute('Unexpected Error');
  }

  render() {
    const { intl } = this.props;

    return (
      <PageBackgroundWrapper>
        <PageSEO title={intl.formatMessage(messages.seoTitle)} />
        <Grid.Container>
          <Grid.Row>
            <Grid.Col md={12} lg={10} offset={{ lg: 1 }} align="center">
              <VerticalSpacing size="sm">
                <img src="/static/assets/other/under-construction-illustration.png" alt="404" />
              </VerticalSpacing>
              <VerticalSpacing size="sm">
                <Text.HeadingFourth tagName="h4" color="quaternary" align="center">
                  {intl.formatMessage(messages.heading)}
                </Text.HeadingFourth>
                <Text.CopyBody tagName="div" align="center">
                  {intl.formatMessage(messages.description)}
                </Text.CopyBody>
                <LocaleLink route="Dashboard" passHref>
                  <Button>{intl.formatMessage(messages.buttonLabel)}</Button>
                </LocaleLink>
              </VerticalSpacing>
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>
      </PageBackgroundWrapper>
    );
  }
}

export default compose(
  injectIntl,
  withAnalytics(),
  withRouting
)(ErrorPage404);
